@import url(https://fonts.googleapis.com/css?family=Raleway:900&display=swap);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap);
body {
  margin: 0;
}
body {
  background-color: #16202c;
  overscroll-behavior-y: contain;
  -webkit-font-smoothing: antialiased;
}
body * {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */ /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

html,
body {
  height: 100%;
}

#root {
  height: 100%;
}

.parallax > use {
    -webkit-animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
            animation: move-forever 25s cubic-bezier(0.55, 0.5, 0.45, 0.5) infinite;
  }
  .parallax > use:nth-child(1) {
    -webkit-animation-delay: -2s;
            animation-delay: -2s;
    -webkit-animation-duration: 7s;
            animation-duration: 7s;
  }
  .parallax > use:nth-child(2) {
    -webkit-animation-delay: -3s;
            animation-delay: -3s;
    -webkit-animation-duration: 10s;
            animation-duration: 10s;
  }
  .parallax > use:nth-child(3) {
    -webkit-animation-delay: -4s;
            animation-delay: -4s;
    -webkit-animation-duration: 13s;
            animation-duration: 13s;
  }
  .parallax > use:nth-child(4) {
    -webkit-animation-delay: -5s;
            animation-delay: -5s;
    -webkit-animation-duration: 20s;
            animation-duration: 20s;
  }
  @-webkit-keyframes move-forever {
    0% {
      -webkit-transform: translate3d(-90px, 0, 0);
              transform: translate3d(-90px, 0, 0);
    }
    100% {
      -webkit-transform: translate3d(85px, 0, 0);
              transform: translate3d(85px, 0, 0);
    }
  }
  @keyframes move-forever {
    0% {
      -webkit-transform: translate3d(-90px, 0, 0);
              transform: translate3d(-90px, 0, 0);
    }
    100% {
      -webkit-transform: translate3d(85px, 0, 0);
              transform: translate3d(85px, 0, 0);
    }
  }
  /*Shrinking for mobile*/
  @media (max-width: 768px) {
    .waves {
      height: 40px;
      min-height: 40px;
    }
    .content {
      height: 30%;
    }
    h1 {
      font-size: 24px;
    }
  }
  
.waves {
  position: absolute;
  width: 100%;
  height: 25%;
  margin-bottom: -7px; /*Fix for safari gap*/
  bottom:0;
}




/* Wrapper */
.icon-button {
	will-change: transform;
	background-color: white;
	border-radius: 3.6rem;
	cursor: pointer;
	display: inline-block;
	font-size: 2.0rem;
	height: 3.6rem;
	line-height: 3.6rem;
	margin: 0;
	position: relative;
	-webkit-user-select: none;
	   -moz-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	width: 25rem;
}

/* Circle */
.icon-button span {
	border-radius: 0;
	display: block;
	height: 0;
	left: 50%;
	margin: 0;
	position: absolute;
	top: 50%;
	-webkit-transition: all 0.3s;
	        transition: all 0.3s;
	width: 0;
}
.icon-button:hover span {
	width: 25rem;
	height: 3.6rem;
	border-radius: 3.6rem;
    margin: -1.8rem;
    margin-left: -12.5rem;
}
.twitter span {
	background-color: #4099ff;
}

/* Icons */
.icon-button i {
	display: flex;
  justify-content: center;
  align-items: center; 
	background: none;
	color: white;
	height: 3.6rem;
	left: 0;
	line-height: 3.6rem;
	position: absolute;
	top: 0;
	-webkit-transition: all 0.3s;
	        transition: all 0.3s;
	width: 25rem;
	z-index: 10;
}
.icon-button .icon-twitter {
	color: #4099ff;
}
.icon-button:hover .icon-twitter {
	color: white;
}

.button .loginButton {
	-webkit-animation-fill-mode: both;
	        animation-fill-mode: both;
	-webkit-animation-delay: 1.7s;
	        animation-delay: 1.7s;
}

.loginButton h2 {
	margin: auto;
	line-height: 1rem;
    font-family: "Open Sans", Arial, Helvetica, sans-serif;
    font-weight: 700;
	font-size:1.3rem;
	font-style: normal;
	color: #4099ff;
	-webkit-transition: all 0.3s;
	        transition: all 0.3s;
}

.icon-button:hover .icon-twitter .textContainer h2 {
	color: white;
}

a {
	text-decoration: none;
}

.textContainer {
	margin: auto;
	width: 70%;
	display: flex;
	flex-direction: row;
	justify-content: space-between
}
.section {
  width: 50%;
  height: 90%;
  display: flex;
}

.section .container {
  margin: auto auto;
  width: 50%;
}

h1 {
  font-family: "Raleway", Arial, Helvetica, sans-serif;
  font-weight: 900;
  font-size: 4vw;
  margin-top: 1rem;
  margin-bottom: 2rem;
  color: #1ea1f1;
  line-height: 35px;
}

.white {
  color: white;
}

.home {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100vw;
  height: 100%;
  background-image: -webkit-linear-gradient(309deg, #16202c 0%, #2a4e7a 95%);
  background-image: linear-gradient(141deg, #16202c 0%, #2a4e7a 95%);

}

h1 span {
  position: relative;
  top: 5px;
  -webkit-animation: bounce 0.5s ease infinite alternate;
          animation: bounce 0.5s ease infinite alternate;
  display: inline-block;
}

h1 span:nth-child(2) {
  -webkit-animation-delay: 0.1s;
          animation-delay: 0.1s;
}
h1 span:nth-child(3) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
h1 span:nth-child(4) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}
h1 span:nth-child(5) {
  -webkit-animation-delay: 0.4s;
          animation-delay: 0.4s;
}
h1 span:nth-child(6) {
  -webkit-animation-delay: 0.5s;
          animation-delay: 0.5s;
}
h1 span:nth-child(7) {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
h1 span:nth-child(8) {
  -webkit-animation-delay: 0.7s;
          animation-delay: 0.7s;
}
h1 span:nth-child(2) {
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}
h1 span:nth-child(2) {
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}

@-webkit-keyframes bounce {
  100% {
    top: -5px;
  }
}

@keyframes bounce {
  100% {
    top: -5px;
  }
}

/* First, we wrap every single word in a .wordContainer div */
/* We set that one to hide it's overflow */
.wordContainer {
  overflow: hidden;
}

/* We define the animation */
@-webkit-keyframes up {
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
}
@keyframes up {
  100% {
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
}

/* Then, every single word gets animated up */
.word {
  -webkit-animation: up 0.8s;
          animation: up 0.8s;
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;
  -webkit-animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
          animation-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1);
  -webkit-transform: translateY(230%);
          transform: translateY(230%);
}

/* Delay for staggering effect */
.textWrapper .wordContainer:nth-child(2) .word {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
.textWrapper .wordContainer:nth-child(3) .word {
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}
.textWrapper .wordContainer:nth-child(4) .word {
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
}

.fade-in {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
  animation: fadeIn ease 1.2s;
  -webkit-animation: fadeIn ease 1.2s;
  -moz-animation: fadeIn ease 1.2s;
  -o-animation: fadeIn ease 1.2s;
  -ms-animation: fadeIn ease 1.2s;
}

.fade-in:nth-child(2) {
  -webkit-animation-delay: 0.6s;
          animation-delay: 0.6s;
}
.fade-in:nth-child(3) {
  -webkit-animation-delay: 0.9s;
          animation-delay: 0.9s;
}
.fade-in:nth-child(4) {
  -webkit-animation-delay: 1.2s;
          animation-delay: 1.2s;
}
.fade-in:nth-child(5) {
  -webkit-animation-delay: 1.5s;
          animation-delay: 1.5s;
}
.fade-in:nth-child(6) {
  -webkit-animation-delay: 1.8s;
          animation-delay: 1.8s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.button {
  margin: auto;
}

@media (max-width: 900px) {
  h1 {
    width: 100%;
  }

  .home {
    flex-direction: column;
  }

  .section {
    width: 100%;
  }

  .section .container {
    width: 90%;
    margin-top: 5%;
  }

  h1 {
    font-size: 4rem;
  }

  .button {
    width: 90%;
  }

  .icon-button {
    width: 100%;
  }

  .textContainer {
    width: 90%;
  }

  .icon-button i {
    width: 100%;
  }

  .waves {
    height: 10%;
  }

  .icon-button:hover span {
    width: 100%;
    margin-left: -50%;
  }
}



.your-tweets {
    background-color: #16202c;
    margin-top: 56px;
}


h3 {
    font-size: 1rem;
    font-family: Roboto;
    margin: auto;
    text-align: center;
}

.loading-screen-tweets {
    display: flex;
    height: calc(100vh - 56px) ;
    width: 100%;
    align-items: center;
    text-align: center;
    align-content: center;
    background-color: #16202c;
    justify-content: space-around;
}

.loading-message {
    height: 20%;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

@media (min-width: 900px) {
    .your-tweets {
        width: 50%;
    }
}
.tweet {
	padding: 1% 2%;
}

.tweet-content {
	padding-top: none !important;
}

.tweet-stats {
	width: 45%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	margin-left: 0;
}

.stat {
	display: flex;
	align-items: center;
	font-family: Raleway;
}

.stat *{
	margin-right: 10%;
}

.red {
	color: red;
}

.lightblue {
	color: lightblue;
}

.lightgreen {
	color: lightgreen;
}

.tweet-footer {
	margin-top: 16px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.date-wrapper {
	width: 100%;
	text-align: right;
}

.extra-wide {
	width: 60%;
}
.confirm {
	margin-left: 16px !important;
}
.error {
    background-color: #d23000 !important;
}

.success {
    background-color: rgb(38, 156, 0) !important;
}

#client-snackbar {
    display: flex;
    align-items: center;
}

.snack-icon {
    margin-right: 15px;
}


.timeline {
    background-color: #16202c;
    margin-top: 56px;
}


h3 {
    font-size: 1rem;
    font-family: Roboto;
    margin: auto;
    text-align: center;
}
.dashboard {
    max-height: 100%;
    min-height: calc(100% - 56px);
    background-color: #16202c;
}

.nav-bar {
    position: absolute;
    bottom: 0;
    width: 100%;
}


.trends {
    background-color: #16202c;
    margin-top: 56px;
}


h3 {
    font-size: 1rem;
    font-family: Roboto;
    margin: auto;
    text-align: center;
}


.footer {
    bottom: 0;
    position: absolute;
}

.current {
    color: #1ea1f1 !important;
    font-weight: 800 !important;
}

.loading-screen {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    align-content: center;
    background-color: #16202c;
}

.spinner {
    margin: auto;
}
