.loading-screen {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: column;
    justify-content: space-around;
    text-align: center;
    align-content: center;
    background-color: #16202c;
}

.spinner {
    margin: auto;
}