@import url("https://fonts.googleapis.com/css?family=Raleway:900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap");
body {
  background-color: #16202c;
  overscroll-behavior-y: contain;
  -webkit-font-smoothing: antialiased;
}
body * {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

html,
body {
  height: 100%;
}

#root {
  height: 100%;
}
